import React, { useEffect, useState } from 'react';
import { Box, Paper, Avatar, Stack, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import Password from './profile/Password';
import UserSection from './UserSection';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function Profile() {
  const [value, setValue] = useState(0);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    if (!selectedImage) {
      // Handle the case when the image is null
      // You can show an alert, display an error message, or perform any other action
      console.log('No image selected');
    } else {
      // Perform the desired action when the image is not null
      console.log('Image selected:', selectedImage);
    }
  };
  useEffect(() => {
    const name = JSON.parse(localStorage.getItem('userName'));
    if (name) {
      setUserName(name);
    }
    const id = JSON.parse(localStorage.getItem('userId'));
    if (id) {
      setUserId(id);
    }
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {/* <Box
        component={Paper}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="10px"
        width={300}
        sx={{ ml: 3 }}
      >
        <Avatar onChange={handleImageChange}>
          <div>
            <input type="file" hidden onChange={handleImageChange} accept="image/*" />
            {previewUrl && <img src={previewUrl} alt="Preview" style={{ width: '200px', height: '200px' }} />}
            <button onClick={handleClick}>Upload</button>
          </div>
        </Avatar>
        <Typography variant="button" display="block" gutterBottom>
          {userName}
        </Typography>
      </Box> */}
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Box
      // sx={{
      //   flexGrow: 1,
      //   bgcolor: 'background.paper',
      //   // display: 'flex',
      //   height: 300,
      //   width: '1000px',
      //   maxWidth: '100%',
      // }}
      >
        <Tabs
          // orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          // sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Password" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <UserSection />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* < userId={userId} /> */}
          <Password userId={userId} />
        </TabPanel>
      </Box>
    </>
  );
}

export default Profile;

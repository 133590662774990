import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

import Router from './routes';
import { Provider } from 'react-redux';
import store from './store/store';
import './App.scss';
import { SnackBarProvider } from './context/SnackBarContext';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <Provider store={store}>
      <SnackBarProvider>
        <ThemeProvider>
          <ScrollToTop />
          <Router />
        </ThemeProvider>
      </SnackBarProvider>
    </Provider>
  );
}

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, Stack, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import Badge from '@mui/material/Badge';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 210;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [logType, setLogType] = useState('');
  useEffect(() => {
    const logType = JSON.parse(localStorage.getItem('type'));
    setLogType(logType);
  }, []);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="ri:menu-4-line" />
        </IconButton>

        {/* <NotificationsPopover /> */}
        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 2, sm: 2.5 }}>
          {/* <LanguagePopover /> */}
          <Box
            sx={{
              width: '100%',
              height: 45,
              border: '1px solid #dce0e4',
              borderRadius: '10px',
              color: 'grey',
              p: 2,
              textOverflow: 'ellipsis',
              display: ['none', 'flex'], // Display none on small screens, flex on larger screens
            }}
            alignItems={'center'}
            overflow={'hidden'}
          >
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textTransform: 'capitalize',
              }}
            >
              {JSON.parse(localStorage.getItem('userName'))}
            </Typography>
          </Box>
          <NotificationsPopover />

          {/* <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
            <Box
              sx={{
                width: '45px',
                height: 45,
                border: '1px solid #dce0e4',
                borderRadius: '50%',
                color: 'black',
                p: 1,
              }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Icon icon="system-uicons:bell" fontSize={50} />
            </Box>
          </Badge> */}

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

import { useState, useRef, useEffect } from 'react';
import { Icon } from '@iconify/react';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Divider,
  Typography,
  IconButton,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import { firestore } from '../../firebase';
import { collection, query, where, limit, orderBy, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------
export default function NotificationsPopover() {
  const navigate = useNavigate();
  const getDefClass = useSelector((state) => state.getClass);
  const getDefBatch = useSelector((state) => state.getBatch);
  const session = useSelector((state) => state.session);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState();
  const [userTyp, setUserTyp] = useState(null);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);

  useEffect(() => {
    const typ = JSON.parse(localStorage.getItem('type'));
    setUserTyp(typ);
    if (typ === 'USER') {
      exitRquestReplay();
      fetchData();
      console.log(data);
    }
  }, []);
  useEffect(() => {
    const typ = JSON.parse(localStorage.getItem('type'));
    if (typ === 'STAFF' && getDefBatch !== null) {
      teacherNotification();
    }
  }, [getDefBatch]);

  async function fetchData() {
    const user_id = JSON.parse(localStorage.getItem('userId'));
    const attendColection = collection(firestore, 'attendance');
    const tenMinutesAgo = new Date();
    tenMinutesAgo.setMinutes(tenMinutesAgo.getMinutes() - 10);
    const q2 = query(
      attendColection,
      where('user_id', '==', user_id),
      where('stat', '==', false),
      where('descr', '==', null),
      where('date', '>', dayjs(tenMinutesAgo).format('YYYY-MM-DD HH:mm:ss')),
      // where('date', '<', dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')),
      orderBy('date', 'desc'),
      limit(1)
    );
    const unsubscribe = onSnapshot(q2, (querySnapshot) => {
      console.log(querySnapshot);
      if (querySnapshot.docs.length > 0) {
        querySnapshot.forEach((doc) => {
          // audio.play();
          setData(doc.data());
        });
      } else {
        setData(null);
      }
    });
  }
  async function exitRquestReplay() {
    console.log('nvgc');
    const user_id = JSON.parse(localStorage.getItem('userId')) || null;
    if (!user_id) {
      console.error('User ID not found in localStorage.');
      return;
    }

    // Assuming 'firestore' is correctly initialized elsewhere in your code
    const attendCollection = collection(firestore, 'break_request');
    const tenMinutesAgo = new Date();

    console.log(user_id, getDefClass, getDefBatch, session, dayjs(new Date()).format('YYYY-MM-DD 00:00:00'));
    const q2 = query(
      attendCollection,
      where('user_id', '==', user_id),
      where('date', '>=', dayjs(new Date()).format('YYYY-MM-DD 00:00:00')),
      where('stat', 'in', [1, 2, 3]), // Use 'in' for multiple values
      orderBy('date', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(q2, (querySnapshot) => {
      console.log(querySnapshot);
      console.log(dayjs(new Date()).format('YYYY-MM-DD 00:00:00'));
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0]; // Get the first document
        setData(doc.data());
        console.log(doc.data());
      } else {
        setData(null);
      }
    });
  }

  async function teacherNotification() {
    setData(0);
    console.log('nvgc');
    const user_id = JSON.parse(localStorage.getItem('userId')) || null;
    if (!user_id) {
      console.error('User ID not found in localStorage.');
      return;
    }

    // Assuming 'firestore' is correctly initialized elsewhere in your code
    const attendCollection = collection(firestore, 'break_request');
    const tenMinutesAgo = new Date();

    console.log(user_id, getDefClass, getDefBatch, session, dayjs(new Date()).format('YYYY-MM-DD 00:00:00'));
    const q2 = query(
      attendCollection,
      where('teacher_id', '==', user_id),
      where('class', '==', getDefClass), // Ensure getDefClass is defined
      where('div', '==', getDefBatch), // Ensure getDefBatch is defined
      where('period', '==', session), // Ensure session is defined
      where('date', '>=', dayjs(new Date()).format('YYYY-MM-DD 00:00:00')),
      where('stat', '==', 0)
    );

    const unsubscribe = onSnapshot(q2, (querySnapshot) => {
      console.log(querySnapshot);
      console.log(dayjs(new Date()).format('YYYY-MM-DD 00:00:00'));
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0]; // Get the first document
        setData(querySnapshot.docs.length);
        console.log(doc.data());
      } else {
        setData(null);
      }
    });
  }
  const handleClose = () => {
    setOpen(null);
  };

  const toMarkAttendance = (subject, period, date) => {
    setOpen(null);
    handleClose();
    navigate('/dashboard/student/mark-attendance', { state: { subject: subject, period: period, date: date } });
  };
  const toExitSection = () => {
    setOpen(null);
    handleClose();
    navigate('/dashboard/teacher/exitNotification');
  };
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handlereqstUpdate = () => {
    console.log('ddd');
    // const firebaseFirestore = firestore.getInstance();
    // const attendCollection = collection(firestore, 'break_request');
    // const documentId = data.id;

    // const updatedData = {
    //   stat: 4,
    // };
    // firebaseFirestore.updateDoc(attendCollection, documentId, updatedData);
    const washingtonRef = doc(firestore, 'break_request', data.id.toString());

    // Set the "capital" field of the city 'DC'
    updateDoc(washingtonRef, {
      stat: 4,
    });
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge color="success" overlap="circular" invisible={data === null ? true : false} variant="dot">
          <Box
            sx={{
              width: '45px',
              height: 45,
              border: '1px solid #dce0e4',
              borderRadius: '50%',
              color: 'black',
              p: 1,
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Icon icon="system-uicons:bell" fontSize={50} />
          </Box>
        </Badge>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Scrollbar sx={{ height: { xs: 'auto', sm: 'auto' } }}>
          {data !== null ? ( // Added parentheses here
            userTyp === 'USER' ? (
              <>
                {data.reason || data.break_time ? (
                  <List disablePadding>
                    <ListItemButton
                      sx={{
                        py: 1.5,
                        px: 1,
                        mt: '1px',
                      }}
                      onClick={handlereqstUpdate}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'background.neutral' }}>
                          <Icon icon="material-symbols:fiber-new" color="#ff6464" width="100px" height="100px" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="heading"
                            sx={{
                              mt: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              color: '#2f2f2f',
                              fontSize: '0.9rem',
                              fontWeight: '700',
                            }}
                          >
                            {data.stat !== 3
                              ? `Your break Request is ${data.stat === 1 ? 'approved' : 'rejected'} by teacher`
                              : 'Your time is over, you are not returned to the class, So teacher marked absent'}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              color: 'text.disabled',
                            }}
                          >
                            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                            {dayjs(data?.date).format('hh:mm')} &nbsp;
                          </Typography>
                        }
                      />
                    </ListItemButton>

                    <Divider />
                  </List>
                ) : (
                  <List disablePadding>
                    <ListItemButton
                      sx={{
                        py: 1.5,
                        px: 2.5,
                        mt: '1px',
                      }}
                      onClick={() => toMarkAttendance(data?.subject, data?.period, data.date)}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'background.neutral' }}>
                          <Icon icon="material-symbols:fiber-new" color="#ff6464" width="100px" height="100px" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="heading"
                            sx={{
                              mt: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              color: '#2f2f2f',
                              fontSize: '0.9rem',
                              fontWeight: '700',
                            }}
                          >
                            {data?.subject} <br /> {data?.period}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              color: 'text.disabled',
                            }}
                          >
                            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                            {dayjs(data?.date).format('hh:mm')} &nbsp;
                          </Typography>
                        }
                      />
                    </ListItemButton>
                    <Divider />
                  </List>
                )}
              </>
            ) : userTyp === 'STAFF' ? (
              <List disablePadding>
                <ListItemButton
                  sx={{
                    py: 1.5,
                    px: 2.5,
                    mt: '1px',
                  }}
                  onClick={toExitSection}
                >
                  {/* <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.neutral' }}>
                      <Icon icon="material-symbols:fiber-new" color="#ff6464" width="100px" height="100px" />
                    </Avatar>
                  </ListItemAvatar> */}
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'background.neutral' }}>
                      {/* <Icon icon="material-symbols:fiber-new" color="#ff6464" width="100px" height="100px" /> */}
                      <img
                        width="36"
                        height="36"
                        src="https://img.icons8.com/color/48/appointment-reminders--v1.png"
                        alt="appointment-reminders--v1"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="heading"
                        sx={{
                          mt: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          color: '#2f2f2f',
                          fontSize: '0.9rem',
                          fontWeight: '500',
                        }}
                      >
                        You have &nbsp; <span style={{ fontWeight: 800 }}>{data}</span>&nbsp; pending break Requests
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                        {dayjs(data?.date).format('hh:mm')} &nbsp;
                      </Typography>
                    }
                  />
                </ListItemButton>
                <Divider />
              </List>
            ) : (
              'admin'
            )
          ) : (
            // Added parentheses here
            <>
              <Box sx={{ p: 2 }} display="flex" justifyContent="center">
                <Typography
                  variant="heading"
                  sx={{
                    mt: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#4c565e',
                    fontSize: '0.9rem',
                    fontWeight: '700',
                  }}
                >
                  No Data &nbsp;
                  <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                </Typography>
              </Box>
            </>
          )}
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

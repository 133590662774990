export const setModule = (module) => ({
  type: 'SET_MODULE',
  payload: module,
});

export const setClass = (className) => ({
  type: 'SET_CLASS',
  payload: className,
});

export const setBatch = (batch) => ({
  type: 'SET_BATCH',
  payload: batch,
});
export const setSession = (session) => ({
  type: 'SET_SESSION',
  payload: session,
});
export const setProfile = (imageData) => ({
  type: 'SET_IMAGE_DATA',
  payload: imageData,
});
export const setLoginRoute = (loginRoute) => ({
  type: 'SET_LOGIN_ROUTE',
  payload: loginRoute,
});

export const resetStore = () => ({
  type: 'RESET_STORE',
});

import React, { useState } from 'react';
import {
  Box,
  Stack,
  TextField,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
  FormControl,
  Snackbar,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import MuiAlert from '@mui/material/Alert';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../config';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);
function Password({ userId }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [confPassword, setConfPassword] = React.useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [passwords, setPasswords] = useState({
    old_pass: null,
    new_pass: null,
    confirm_pass: null,
    id: userId,
  });

  const [open, setOpen] = React.useState(false);
  const [snack, setSnack] = useState({
    typ: '',
    message: '',
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleConfShowPassword = () => setConfPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    const newData = { ...passwords };
    newData[fieldName] = fieldValue;
    setPasswords(newData);
  };
  const handleSubmit = () => {
    passwords.id = userId;

    axios
      .post(`${baseUrl}api/changePassword`, { passwords }, { withCredentials: true })
      .then((res) => {
        // if (res.data.success === true) {
        // }
        if (res.data) {
          setOpen(true);
          setSnack({
            typ: 'success',
            message: 'Password Changed',
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate(`/login`, { replace: true });
        }
        if (err.response.status === 422) {
          setOpen(true);
          setSnack({
            typ: 'error',
            message: err.response.data,
          });
        }
      });
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        style={{ bottom: '24px', left: 'auto', right: '24px' }}
      >
        <Alert onClose={handleClose} severity={snack.typ} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <Stack direction="column" spacing={2}>
          <TextField
            id="outlined-basic"
            label="Current Password"
            variant="outlined"
            name="old_pass"
            onChange={handlePasswordChange}
          />
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              name="new_pass"
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
            <OutlinedInput
              error={passwordMatch}
              id="outlined-adornment-password"
              type={confPassword ? 'text' : 'password'}
              name="confirm_pass"
              onChange={handlePasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleConfShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {confPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
            {passwordMatch ? <FormHelperText id="component-error-text">Password does not Match</FormHelperText> : null}
          </FormControl>
          <Button variant="contained" onClick={handleSubmit}>
            {' '}
            Change Password
          </Button>
        </Stack>
      </Box>
    </>
  );
}

export default Password;

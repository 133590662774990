import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, CardMedia, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../config';

function Colleagues() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${baseUrl}api/getColleagues`, { withCredentials: true })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate(`/login`, { replace: true }); // Redirects to login page if not authorized
        }
      });
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        {data.map((data) => {
          return (
            <Grid item xs={12} sm={6} md={4}>
              <Box component={Paper} elevation={10} height={398} sx={{ borderRadius: 2 }}>
                <CardMedia
                  component="img"
                  alt="Colleagues Profile"
                  height="294"
                  image={
                    data.profile_img
                      ? data.profile_img
                      : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                  }
                />
                <Box sx={{ pl: 3, mt: 1 }}>
                  <Typography variant="h4" gutterBottom sx={{ color: '#A5B3CD' }}>
                    {data.Name}
                  </Typography>
                  {/* <Typography variant="subtitle2" gutterBottom sx={{ color: '#A5B3CD', fontWeight: 400 }}>
                    {data.module}
                  </Typography> */}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Colleagues;

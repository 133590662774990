// @mui
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Box } from '@mui/material';
// hooks
import axios from 'axios';
import useResponsive from './hooks/useResponsive';
// components
import { baseUrl } from './config';
import Page from './components/Page';
// sections
import { LoginForm } from './sections/auth/login';
// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  margin: theme.spacing(2, 4, 2, 2),
}));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function Login() {
  const [company, setCompany] = useState([]);
  useEffect(() => {
    axios.get(`${baseUrl}api/company`, { withCredentials: true }).then((res) => {
      setCompany(res.data[0]);
    });
  }, []);

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  return (
    <Page title="Login" style={{ backgroundColor: '#edf6ff' }}>
      <RootStyle>
        <Container maxWidth="sm">
          {/* <Card sx={{ p: 2 }}> */}
          <ContentStyle>
            {!mdUp && (
              <>
                <Box width={58} height={58}>
                  <img src={company.img} alt="companylogo" width={'100%'} height={'100%'} />
                </Box>
                <Typography variant="h4" gutterBottom>
                  {company.Name}
                </Typography>
                <Typography sx={{ color: 'text.secondary', mb: 5 }}> {company.sub_name}</Typography>
              </>
            )}
            <LoginForm />
          </ContentStyle>
          {/* </Card> */}
        </Container>
        {mdUp && (
          <>
            <SectionStyle>
              <Box>
                <Box sx={{ px: 5 }}>
                  <img src={company.img} alt="companylogo" width={58} height={58} />
                </Box>
                <Typography variant="body1" sx={{ px: 5, mt: 2 }}>
                  Hi, Welcome
                </Typography>
                <Typography variant="h3" sx={{ px: 5 }}>
                  {company.Name}
                </Typography>
                <Typography variant="subtitle1" sx={{ px: 5, mb: 5 }}>
                  {company.sub_name}
                </Typography>
              </Box>
              <img
                style={{ width: '25rem' }}
                src="/static/illustrations/illustration_login.jpg"
                alt="login"
                loading="lazy"
              />
              <Typography variant="p" sx={{ width: '100%', textAlign: 'right',px:4 }}>
                <a
                  href="http://wizzo.in/"
                  style={{
                    color:'grey',
                    textDecoration: 'none',
                    width: '100%',
                    fontSize: '1rem',
                  }}
                >
                  www.wizzo.in
                </a>
              </Typography>
            </SectionStyle>
          </>
        )}
      </RootStyle>
    </Page>
  );
}
// sample

import React, { createContext, useState, useContext } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Create the Snackbar context
const SnackBarContext = createContext();

// Custom hook to access the Snackbar context
export const useSnackBar = () => {
  return useContext(SnackBarContext);
};

// SnackBarProvider component to wrap your application with
export function SnackBarProvider({ children }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    typ: '',
  });

  // Function to show the Snackbar
  const showSnackbar = (type, message) => {
    setSnackbarMessage({ message, typ: type });
    setSnackbarOpen(true);
  };

  // Function to hide the Snackbar
  const hideSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackBarContext.Provider value={{ snackbarOpen, snackbarMessage, showSnackbar, hideSnackbar }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={hideSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={hideSnackbar} severity={snackbarMessage.typ} sx={{ background: snackbarMessage.typ }}>
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
}



// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const navConfigTeacher = [
  {
    title: 'dashboard',
    path: '/dashboard/teacher/app',
    icon: getIcon('fluent:grid-28-filled'),
  },

  {
    title: 'Attendance',
    path: '/dashboard/teacher/attendence',
    icon: getIcon('tabler:chart-arcs'),
  },
  {
    title: 'Attendance Report ',
    path: '/dashboard/teacher/attedanceReport',
    icon: getIcon('solar:graph-new-linear'),
  },
  {
    title: 'Exit notification ',
    path: '/dashboard/teacher/exitNotification',
    icon: getIcon('fluent:picture-in-picture-exit-16-regular'),
  },
  {
    title: 'absent request',
    path: '/dashboard/teacher/absentReq',
    icon: getIcon('mdi:progress-clock'),
  },
  {
    title: 'Edit Attendance ',
    path: '/dashboard/teacher/editAtt',
    icon: getIcon('icon-park-outline:edit-one'),
  },
  {
    title: 'calender',
    path: '/dashboard/admin/calender',
    icon: getIcon('system-uicons:calendar-date'),
  },
  {
    title: 'My Colleagues',
    path: '/dashboard/teacher/colleagues',
    icon: getIcon('solar:users-group-rounded-bold-duotone'),
  },
  {
    title: 'Settings',
    path: '',
    icon: getIcon('octicon:shield-lock-24'),
  },
  {
    title: 'schedule',
    path: '',
    icon: getIcon('uis:schedule'),
  },
  // attedanceReport
  // editAtt
  // }else{

  // }
];

export default navConfigTeacher;

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { Box, Drawer, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setProfile } from '../../store/action';
import { Icon } from '@iconify/react';
import useResponsive from '../../hooks/useResponsive';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import navConfigStudent from './NavConfigStudent';
import navConfigTeacher from './NavConfigTeacher';
import { baseUrl } from '../../config';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // const logType = 'ADMIN';
  const [sideConfig, setSideConfig] = useState(navConfigTeacher);

  const isDesktop = useResponsive('up', 'lg');
  const [logType, setLogtype] = useState('');
  const [company, setCompany] = useState([]);
  useEffect(() => {
    axios.get(`${baseUrl}api/company`, { withCredentials: true }).then((res) => {
      setCompany(res.data[0]);
    });
  }, []);
  useEffect(() => {
    function getUser() {
      axios
        .get(`${baseUrl}api/getUser`, { withCredentials: true })
        .then((res) => {
          setLogtype(res.data.data.typ);

          dispatch(setProfile(res.data.data.profile));
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate(`/login`, { replace: true });
          }
        });
      // const items = JSON.parse(localStorage.getItem('type'));
      // if (items) {

      //   setLogtype(items);
      // }
    }
    getUser();
  }, [pathname]);
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (logType === 'USER') {
      setSideConfig(navConfigStudent);
    } else if (logType === 'ADMIN') {
      setSideConfig(navConfig);
    } else if (logType === 'STAFF') {
      setSideConfig(navConfigTeacher);
    }
  }, [pathname, logType]);

  const handleLogOut = () => {
    axios
      .post(`${baseUrl}api/logout`, { withCredentials: true })
      .then((res) => {
        if (res.data.data === 'success');
        navigate(`/login`, { replace: true });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate(`/login`, { replace: true });
        }
      });
  };

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        }}
      >
        <Box sx={{ px: 2.5, py: 3, display: 'flex', height: 110, alignItems: 'center' }}>
          {' '}
          {/* <span style={{ textAlign: 'center' }}>International College of Engineering</span> */}
          <img src={company.img} alt="#" width={70} height={70} />
        </Box>

        {/* <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle>
              <img src={logoStark} alt="sdfg" style={{ width: '48px', height: '48px', borderRadius: '50%' }} />
         
              <Box sx={{ ml: 2 }}>
                <Typography variant="h4" sx={{ color: 'text.primary' }}>
                  STARK
                </Typography>
               
              </Box>
            </AccountStyle>
          </Link>
        </Box> */}
        <Box sx={{ pl: 2.3 }}>
          <Typography variant="subtitle2" gutterBottom color="rgba(177, 189, 211, 1)">
            Manage
          </Typography>
        </Box>
        <NavSection navConfig={sideConfig} />

        <Box sx={{ flexGrow: 1 }} />
        {/* <Box sx={{ pl: 2.3 }}>
          fgh
          </Box> */}
        <List>
          <ListItem>
            <Icon icon="solar:logout-2-outline" fontSize={20} color="#bfc9db" />
            <ListItemButton sx={{ fontWeight: 400, color: '#bfc9db', fontSize: 15 }} onClick={handleLogOut}>
              Logout
            </ListItemButton>
          </ListItem>
        </List>
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, background: '#fff' },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#fff ',
              borderRightStyle: 'solid',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

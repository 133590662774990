import { createStore } from 'redux';
import { useDispatch } from 'react-redux';

// Define an initial state
const initialState = {
  getModule: null,
  getClass: null,
  getBatch: null,
  session: null,
  imageData: null,
  loginRoute: '/login',
};

// Define a reducer function
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODULE':
      return { ...state, getModule: action.payload };
    case 'SET_CLASS':
      return { ...state, getClass: action.payload };
    case 'SET_BATCH':
      return { ...state, getBatch: action.payload };
    case 'SET_SESSION':
      return { ...state, session: action.payload };
    case 'SET_IMAGE_DATA':
      return { ...state, imageData: action.payload };
    case 'SET_LOGIN_ROUTE':
      return { ...state, loginRoute: action.payload };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
};

// Create the Redux store
const store = createStore(rootReducer);

// Export the store
export default store;

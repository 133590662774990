import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

function BottomNav() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(1);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: 'rgb(0 0 0 / 14%) 1px -6px 14px 1px',
      }}
    >
      <BottomNavigation
        sx={{ background: '#63EAE2', color: '#fff' }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          sx={{
            color: value === 0 ? '#FF0000' : '#fff', // Change color if active
          }}
          onClick={() => handleNavigation('/dashboard/student/take.break')}
          label="Break Request"
          icon={<Icon icon="carbon:request-quote" fontSize={26} />}
        />
        <BottomNavigationAction
          sx={{
            color: value === 1 ? '#FF0000' : '#fff', // Change color if active
          }}
          onClick={() => handleNavigation('/dashboard/student/app')}
          label="Dashboard"
          icon={<Icon icon="fluent:grid-28-filled" fontSize={26} />}
        />
        <BottomNavigationAction
          sx={{
            color: value === 2 ? '#FF0000' : '#fff', // Change color if active
          }}
          onClick={() => handleNavigation('/dashboard/student/subject_report')}
          label="Report"
          icon={<Icon icon="solar:graph-new-linear" fontSize={26} />}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNav;

import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getFirestore } from 'firebase/firestore'; // Use getFirestore to import Firestore

const firebaseConfig = {
  apiKey: 'AIzaSyB-TwnebUEN-fUpVMtli9GgIBQryHOdzJo',
  authDomain: 'hodoor-institution.firebaseapp.com',
  projectId: 'hodoor-institution',
  storageBucket: 'hodoor-institution.appspot.com',
  messagingSenderId: '467350620127',
  appId: '1:467350620127:web:5c04f26e72e431e1a4266a',
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const firestore =  getFirestore(app); // Use getFirestore to initialize Firestore

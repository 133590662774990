import React, { useState, useEffect } from 'react';
import { Box, TextField, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../config';
import { useSnackBar } from '../context/SnackBarContext';

function CompanyInfo({ setOpenAcc }) {
  const { showSnackbar } = useSnackBar();
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState(null);
  const [file, setFile] = useState(null);
  const [company, setCompany] = useState([]);
  const [details, setDetails] = useState({
    companyName: '',
    sub_name: '',
    companyAddress: '',
    companyAddressTwo: '',
    companyGst: '',
    companyMobNo: '',
    companyTeleNo: '',
    companyEmail: '',
    comapanyWeb: '',
    img: null,
  });
  const handleError = () => {
    showSnackbar('error', 'Oops! Something went wrong.');
  };

  const handleComplt = () => {
    showSnackbar('success', 'Company info Added');
  };
  const handleUpdate = () => {
    showSnackbar('success', 'Company info Updated');
  };

  useEffect(() => {
    async function getCompany() {
      await axios.get(`${baseUrl}api/company`, { withCredentials: true }).then((res) => {
        const companyData = {
          companyName: res.data[0].Name,
          sub_name: res.data[0].sub_name,
          companyAddress: res.data[0].Place,
          companyAddressTwo: res.data[0].Add1,
          companyGst: res.data[0].Add2,
          companyMobNo: res.data[0].Ph,
          companyTeleNo: res.data[0].Country,
          companyEmail: res.data[0].EMail,
          comapanyWeb: res.data[0].Web,
          img: res.data[0].img,
        };
        setImageSrc(res.data[0].img);
        setFile(res.data[0].img); // Set image source for preview
        setDetails(companyData);
      });
    }
    getCompany();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageSrc(URL.createObjectURL(file)); // Set image source for preview
    setFile(file);
  };

  const handleCompanyDetail = (event) => {
    const inputFieldValue = event.target.value;
    const inputFieldName = event.target.name;
    const newInputValue = {
      ...details,
      [inputFieldName]: inputFieldValue,
    };
    setDetails(newInputValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('companyName', details.companyName);
    formData.append('sub_name', details.sub_name);
    formData.append('companyAddress', details.companyAddress);
    formData.append('companyAddressTwo', details.companyAddressTwo);
    formData.append('companyGst', details.companyGst);
    formData.append('companyMobNo', details.companyMobNo);
    formData.append('companyTeleNo', details.companyTeleNo);
    formData.append('companyEmail', details.companyEmail);
    formData.append('comapanyWeb', details.comapanyWeb);

    axios
      .post(`${baseUrl}api/updateCompany`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success) {
          handleUpdate();
          setOpenAcc(false);
        } else {
          handleError();
        }
      })
      .catch((err) => {
        handleError();
        if (err.response.status === 403) {
          handleError();
          navigate(`/login`, { replace: true });
        }
      });
  };

  return (
    <>
      <Box component="form" sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6}>
            <Box sx={{ width: 150, height: 150 }}>
              {imageSrc ? (
                <img src={imageSrc} alt="Preview" style={{ width: '150px', height: '150px', objectFit: 'fill' }} />
              ) : (
                <img src={details.img} style={{ width: '150px', height: '150px', objectFit: 'fill' }} alt="Default" />
              )}
            </Box>
            <Button component="label" size="small">
              Upload
              <input hidden accept="image/*" multiple type="file" onChange={handleFileChange} />
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Company Name"
              variant="outlined"
              name="companyName"
              value={details.companyName}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Subtitle"
              variant="outlined"
              name="sub_name"
              value={details.sub_name}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              name="companyAddress"
              value={details.companyAddress}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Address Two"
              variant="outlined"
              name="companyAddressTwo"
              value={details.companyAddressTwo}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="VAT Number"
              variant="outlined"
              name="companyGst"
              value={details.companyGst}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Mobile Number"
              variant="outlined"
              name="companyMobNo"
              value={details.companyMobNo}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Telephone Number"
              variant="outlined"
              name="companyTeleNo"
              value={details.companyTeleNo}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="companyEmail"
              value={details.companyEmail}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <TextField
              fullWidth
              label="Web Address"
              variant="outlined"
              name="comapanyWeb"
              value={details.comapanyWeb}
              onChange={handleCompanyDetail}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button variant="contained" onClick={handleSubmit} sx={{ width: '100%' }}>
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CompanyInfo;

// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const navConfigStudent = [
  {
    title: 'dashboard',
    path: '/dashboard/student/app',
    icon: getIcon('fluent:grid-28-filled'),
  },
  // take.break
  {
    title: 'Break request',
    path: '/dashboard/student/take.break',
    icon: getIcon('material-symbols:avg-time-outline'),
  },
  {
    title: 'Leave Request',
    path: '/dashboard/student/take_leave',
    icon: getIcon('tabler:user-x'),
  },
  {
    title: 'calender',
    path: '/dashboard/student/calender',
    icon: getIcon('system-uicons:calendar-date'),
  },

  {
    title: 'Report',
    path: '/dashboard/student/subject_report',
    icon: getIcon('solar:graph-new-linear'),
  },
  {
    title: 'schedule',
    path: '',
    icon: getIcon('uis:schedule'),
  },
  // take_leave
];

export default navConfigStudent;

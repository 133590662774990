import React, { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Box, Stack, TextField, Button, Divider, Link, Alert, Modal } from '@mui/material';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
// import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { AES } from 'crypto-js';
import { baseUrl } from '../../config';
import Page from '../../components/Page';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '60vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0),
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: 1,
  boxShadow: 24,

  p: 4,
  textAlign: 'center',
};

function ForgottPassword() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [createOtp, setCreateOTP] = useState(null);
  const [otpValue, setOtpValue] = useState(null);
  const [otSection, setOtSection] = useState(false);
  const [matchPass, setMatchPass] = useState(null);
  const [newPass, setNewPass] = useState('');
  const [confPass, setConfPass] = useState('');
  const [resetPass, setResetPass] = useState(false);
  const [error, setError] = useState('');
  // const naviggate = useNavigate();
  const naviggate=useNavigate()
  const handleOpen = () => {
    if (createOtp === otpValue) {
      setOpen(true);
    } else {
      setError('OTP verification failed, try agin');
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };
  const handleNavi = () => {
    naviggate('/login');
  };
  useEffect(() => {
    if (createOtp) {
      handleSentOtp();
    }
  }, [createOtp]);
  const handleSentOtp = () => {
    setLoading(true);

    setIsValid(false);
    // Encrypt the payload
    const secretKey = 'mySecretKey';
    const encryptedPayload = AES.encrypt(JSON.stringify({ email, createOtp }), secretKey).toString();

    axios
      .post(`${baseUrl}api/sendForgotOTP`, { encryptedPayload }, { withCredentials: true })
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          setOtSection(true);
        } else {
          setError("This email doesn't exist, Try another one");
          setTimeout(() => {
            setError('');
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          naviggate(`/login`, { replace: true });
        }
      });
  };
  const generateOTP = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      const digits = '0123456789';
      let otp = '';

      for (let i = 0; i < 6; i += 1) {
        const randomIndex = Math.floor(Math.random() * digits.length);
        otp += digits[randomIndex];
      }
      setCreateOTP(otp);
    } else {
      setIsValid(true);
    }
  };

  const handleOTPChange = (e) => {
    const value = e.target.value;
    const otpRegex = /^[0-9]{0,6}$/; // Regular expression to match 0-6 digits

    if (otpRegex.test(value)) {
      setOtpValue(value);
    }
  };
  const handleNewPass = (e) => {
    setNewPass(e.target.value);
    if (e.target.value === confPass) {
      setResetPass(false);
    } else {
      setResetPass(true);
    }
  };
  const handleConfirmPass = (e) => {
    setConfPass(e.target.value);
    if (e.target.value)
      if (newPass !== e.target.value) {
        setMatchPass(true);
        setResetPass(true);
      } else {
        setMatchPass(false);
        setResetPass(false);
      }
  };
  const handleSubmitPassword = () => {
    axios
      .post(`${baseUrl}api/resetPassword`, { "email": email, "password": confPass }, { withCredentials: true })
      .then((res) => {
        if (res.data.success) {
          naviggate('/login');
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          naviggate(`/login`, { replace: true });
        }
      });
  };
  return (
    <Page title="Login">
      <RootStyle>
        <Container maxWidth="sm">
          <Card sx={{ p: 2 }}>
            {error && (
              <Alert severity="error" onClose={() => setError('')}>
                {error}
              </Alert>
            )}
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
              width={'100%'}
              sx={{ color: '#2f2f2f', mt: 8 }}
            >
              <Icon icon="mdi:cloud-lock-outline" width={58} color="#acacac" />
              <Typography variant="h4" gutterBottom>
                Trouble in Login
              </Typography>
            </Box>
            {/* <Divider /> */}
            {!otSection ? (
              <>
                <ContentStyle>
                  <form>
                    <Box>
                      <Typography variant="subtitle2" gutterBottom sx={{ color: 'grey', mb: 3, fontWeight: 400 }}>
                        Enter your email, We'll send you a OTP (One Time password) to Reset Password, Don't Share it.
                      </Typography>
                      <Stack direction={'column'} spacing={3}>
                        <TextField
                          error={isValid}
                          id="email_basic"
                          fullWidth
                          variant="outlined"
                          name="email"
                          label="Email Address"
                          required
                          helperText={isValid ? 'Invalid Email' : ''}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <LoadingButton
                          loading={loading}
                          loadingPosition="center"
                          disabled={!email}
                          variant="contained"
                          onClick={generateOTP}
                        >
                          Send OTP
                        </LoadingButton>
                      </Stack>
                    </Box>
                  </form>
                </ContentStyle>
              </>
            ) : (
              // <ContentStyle>
              <>
                <Box className="verification_sec">
                  <Stack direction={'column'} spacing={2}>
                    <Typography variant="subtitle2" gutterBottom color={'grey'}>
                      Please check your email for your code. Your code is 6 characters in length. Don't share it anyone.
                    </Typography>
                    <input type="number" id="otpInput" value={otpValue} onChange={handleOTPChange} maxLength={6} />
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                      Verify OTP
                    </Button>
                  </Stack>
                </Box>
                <Modal
                  open={open}
                  // onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Reset password
                    </Typography>
                    <Divider />
                    <Stack direction={'column'} spacing={2} sx={{ py: 3 }}>
                      <Typography id="modal-modal-title" variant="subtitle2" gutterTop>
                        Here you can create new paassword
                      </Typography>
                      <TextField label="New Password" type="password" onChange={handleNewPass} />
                      <TextField
                        error={matchPass}
                        label="Confirm Password"
                        type="password"
                        onChange={handleConfirmPass}
                      />
                      <Button disabled={resetPass} variant="contained" onClick={handleSubmitPassword}>
                        Change Password
                      </Button>
                    </Stack>
                  </Box>
                </Modal>
              </>
              // </ContentStyle>
            )}
            <Divider />
            <Stack
              sx={{
                pt: 1,
                textAlign: 'center',
              }}
            >
              <Link
                onClick={handleNavi}
                variant="subtitle2"
                sx={{
                  cursor: 'pointer',
                  fontWeight: 400,
                  textDecoration: 'none',
                  '&:hover': {
                    fontWeight: 600,
                  },
                }}
              >
                Back to login
              </Link>
            </Stack>
          </Card>
        </Container>
      </RootStyle>
    </Page>
  );
}

export default ForgottPassword;

import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Box, Stack, Typography } from '@mui/material';
import Label from '../components/Label';

function EachStudentPrint() {
  const location = useLocation();
  console.log(location.state.allReports);
  const navigate = useNavigate();
  const printAreaRef = useRef(null);
  useEffect(() => {
    handlePrint();
  }, []);

  const handlePrint = () => {
    const printArea = printAreaRef.current;
    if (!printArea) {
      console.error("Element with ID 'print-area' not found.");
      return;
    }

    const printContents = printArea.innerHTML;
    const printWindow = window.open('', '_blank');

    if (!printWindow) {
      console.error("Failed to open the print window. Please check your browser's popup settings.");
      return;
    }
    // <p style='margin: 0;'>${dayjs(location.state.sDate).format('DD/MM/YYYY')} to ${dayjs(
    //     location.state.eDate
    //   ).format('DD/MM/YYYY')} </p>
    // <h2 style='margin: 2px;'>${location.state.class} ${location.state.division}</h2>
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          <div style='text-align: center;' className="vt_print_ther_head">
          </div>
          ${printContents}
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();

    setTimeout(() => {
      printWindow.close();
      navigate(-1);
    }, 50); // Adjust the timeout duration as needed
  };
  console.log(location.state.counts);
  const AllPresent = location.state.counts.reduce((total, counts) => total + counts.present, 0);
  const AllAbsent = location.state.counts.reduce((total, counts) => total + counts.abscent, 0);
  return (
    <>
      <div className="vt_print_ther_head" ref={printAreaRef} id="print-area">

        {/* Dynamic Table */}
        <div style={{ marginTop: 4 }}>
          <table aria-label="simple table" style={{ width: '100%', borderCollapse: 'collapse', fontSize: '1rem' }}>
            <tbody>
              {location.state.allReports?.map((item, index) => {
                const attendanceData = JSON.parse(item.attendance);
                if (attendanceData) {
                  return (
                    <tr key={index}>
                      <td width={'90px'} style={{ border: '1px solid #dcdcdc', padding: '8px' }}>
                        {dayjs(item.Date1).format('DD/MM/YYYY')}
                      </td>
                      {attendanceData.map((attendanceItem, innerIndex) => (
                        <td
                          key={innerIndex}
                          width={'170px'}
                          align="center"
                          style={{ border: '1px solid #dcdcdc', padding: '8px' }}
                        >
                          {attendanceItem.subject}
                          <br />
                          <div
                            style={{
                              color:
                                attendanceItem.stat === 0
                                  ? 'red'
                                  : attendanceItem.description !== null
                                  ? 'blue'
                                  : 'green',
                            }}
                          >
                            {attendanceItem.stat === 0
                              ? 'Absent'
                              : attendanceItem.description !== null
                              ? 'Authorized Absent'
                              : 'Present'}
                          </div>
                        </td>
                      ))}
                    </tr>
                  );
                }
                // Add a return statement here in case attendanceData is null
                return null;
              })}
            </tbody>
          </table>
          {/* Total Row */}
          <div
            style={{
              background: '#dcdcdc',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '90px', border: '1px solid #dcdcdc' }}>Total</div>
            {location.state.counts?.map((counts) => (
              <div
                key={counts.subject}
                style={{ width: '170px', border: '1px solid #dcdcdc', textAlign: 'center', fontSize: '1rem' }}
              >
                {/* Display subject name */}
                {counts.subject}
                <br />
                {/* Display attendance counts */}
                <div style={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ color: 'green', padding: 5 }}>{counts.present}</div>
                  <div style={{ color: 'red', padding: 5 }}>{counts.abscent}</div>
                </div>
                {/* Display total attendance */}
                <Label>Total: {counts.present + counts.abscent}</Label>
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            width: '100%',
            color: 'black',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ cololr: 'green' }}>Total Present: {AllPresent}</div>
            <div style={{ cololr: 'red' }}>Total Absent: {AllAbsent}</div>
            <div style={{ cololr: 'Blue' }}>Percentage: {(AllAbsent / AllPresent) * 100}%</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EachStudentPrint;

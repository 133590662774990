import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import { useDispatch } from 'react-redux';
// @mui
import { useSelector } from 'react-redux';
import { alpha, useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Avatar,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Profile from '../../Profile';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import { baseUrl } from '../../config';
import CompanyInfo from '../../company/CompanyInfo';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [open, setOpen] = useState(null);
  const [openAcc, setOpenAcc] = React.useState(false);
  const imageData = useSelector((state) => state.imageData);
  const [logType, setLogType] = useState('');
  const [modal, setModal] = useState('');

  useEffect(() => {
    const logType = JSON.parse(localStorage.getItem('type'));
    setLogType(logType);
  }, []);
  const handleCloseAcc = () => {
    setOpenAcc(false);
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('userName'));
    if (items) {
      setName(items);
    }
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = () => {
    axios
      .post(`${baseUrl}api/logout`, { withCredentials: true })
      .then((res) => {
        if (res.data.data === 'success');
        dispatch({ type: 'RESET_STORE' });
        navigate(`/login`, { replace: true });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate(`/login`, { replace: true });
        }
      });
  };
  const handleAccount = (typ) => {
    setModal(typ);
    setOpenAcc(true);
  };

  return (
    <>
      <Dialog
        open={openAcc}
        // fullScreen={fullScreen}
        onClose={handleCloseAcc}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{modal === 'profile' ? 'Profile' : 'Company Details'}</DialogTitle>
        <DialogContent>
          {modal === 'profile' ? <Profile setOpenAcc={setOpenAcc} /> : <CompanyInfo setOpenAcc={setOpenAcc} />}
        </DialogContent>
        {modal === 'profile' ? (
          <DialogActions>
            <Button onClick={handleCloseAcc} autoFocus>
              Back
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={imageData} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap sx={{ textTransform: 'capitalize' }}>
            {name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'solid' }} />
        {logType === 'ADMIN' ? (
          <MenuItem sx={{ m: 1 }} onClick={() => handleAccount('company')}>
            Company
          </MenuItem>
        ) : null}
        <MenuItem onClick={() => handleAccount('profile')} sx={{ m: 1 }}>
          Profile
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

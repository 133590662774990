// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={24} height={24} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/admin/app',
    icon: getIcon('fluent:grid-28-filled'),
  },
  {
    title: 'General',
    path: '/dashboard/admin/master',
    icon: getIcon('tabler:chart-arcs'),
  },
  {
    title: 'Edit Attendance ',
    path: '/dashboard/teacher/editAtt',
    icon: getIcon('lucide:edit'),
  },
  // att-report
  {
    title: 'calender',
    path: '/dashboard/admin/calender',
    icon: getIcon('system-uicons:calendar-date'),
  },
  {
    title: 'Modes',
    path: '/dashboard/admin/modes',
    icon: getIcon('ph:swap-light'),
  },
  {
    title: 'reports',
    path: '/dashboard/admin/att-report',
    icon: getIcon('solar:graph-new-linear'),
  },
  {
    title: 'security',
    path: '/dashboard/admin/passwords',
    icon: getIcon('octicon:shield-lock-24'),
  },
  // {
  //   title: 'my colleagues',
  //   path: '',
  //   icon: getIcon('solar:users-group-rounded-bold-duotone'),
  // },
  {
    title: 'settings',
    path: '',
    icon: getIcon('solar:settings-linear'),
  },
  {
    title: 'schedule',
    path: '',
    icon: getIcon('uis:schedule'),
  },
];

export default navConfig;

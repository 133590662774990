import * as Yup from 'yup';

import { getToken } from 'firebase/messaging';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment, Link, Modal, Typography, Box, Button } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoginRoute } from '../../../store/action';
import { LoadingButton } from '@mui/lab';
import { baseUrl } from '../../../config';
import Iconify from '../../../components/Iconify';
import { messaging } from '../../../firebase';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function requestPermission() {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        try {
          const token = await getToken(messaging, {
            vapidKey: 'BNz383erEMYtbiYbhTxE2XYA4XP7zVPp9LdVO4P6-x9AC62-xjCvwafxIKXx1evFxeBchI2B4HBTLFo7h9FG-Xk',
          });
          setToken(token);
          localStorage.setItem('token', JSON.stringify(token));
        } catch (error) {
          console.error('Error while getting token:', error);
        }
      } else if (permission === 'denied') {
        alert('You denied notifications');
        setLogin(true);
        await Notification.requestPermission();
      }
    }
    requestPermission();
  }, []);

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const LoginSchema = Yup.object().shape({
    userId: Yup.string().required('ID required'),
    password: Yup.string().required('Password is required'),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      userId: '',
      password: '',
      remember: true,
    },
  });

  // const {
  //   formState: { isSubmitting },
  // } = methods;

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(token);
    setLoading(true);
    // if (login) {
    //   Notification.requestPermission();
    //   alert('You denied notifications');
    //   return;
    // }
    try {
      const res = await axios.post(
        `${baseUrl}api/login`,
        { username: userName, password: password, token: token },
        { withCredentials: true }
      );

      if (res.data.success) {
        localStorage.setItem('userId', JSON.stringify(res.data.data.id));
        localStorage.setItem('userName', JSON.stringify(res.data.data.name));
        localStorage.setItem('type', JSON.stringify(res.data.data.typ));

        switch (res.data.data.typ) {
          case 'STAFF':
            navigate('/dashboard/teacher/app', { replace: true, state: { clearHistory: true } });
            dispatch(setLoginRoute('/dashboard/teacher/app'));
            break;
          case 'USER':
            navigate('/dashboard/student/app', { replace: true, state: { clearHistory: true } });
            dispatch(setLoginRoute('/dashboard/student/app'));
            break;
          case 'ADMIN':
            navigate('/dashboard/admin/app', { replace: true, state: { clearHistory: true } });
            dispatch(setLoginRoute('/dashboard/admin/app'));
            break;
          default:
            break;
        }
      } else if (res.data.message === 'Incorrect Token') {
        setAlertMsg("You can't login in this device, Use your device");
        setLoading(false);
        setOpen(true);
      } else {
        setAlertMsg('Incorrect username or password, try again..');
        setLoading(false);
        setOpen(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoading(false);
    }
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 1,
    p: 3,
  };
  return (
    <FormProvider methods={methods}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Warning
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {alertMsg}
          </Typography>
          <Stack sx={{ mt: 3 }}>
            <Button variant="contained" color="warning" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Stack spacing={3}>
        {/* Your form fields */}
        <RHFTextField name="userId" label="User ID" value={userName} onChange={handleUserName} />
        <RHFTextField
          name="password"
          label="Password"
          onChange={handlePassword}
          value={password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        onClick={onSubmit}
        variant="contained"
        loading={loading}
        sx={{ mt: 3 }}
      >
        Login
      </LoadingButton>
      <Stack sx={{ mt: 3, textAlign: 'center' }}>
        <Link
          href="/login_tr"
          variant="subtitle2"
          sx={{
            cursor: 'pointer',
            fontWeight: 400,
            textDecoration: 'none',
            '&:hover': {
              fontWeight: 600,
            },
          }}
        >
          Forgotten password?
        </Link>
      </Stack>
    </FormProvider>
  );
}

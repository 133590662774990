import React, { useState, useEffect } from 'react';
import { Box, Button, Avatar } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from './config';

function UserSection() {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [logType, setLogType] = useState('');
  useEffect(() => {
    const logType = JSON.parse(localStorage.getItem('type'));
    setLogType(logType);
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}api/getProfilePicture`, { withCredentials: true })
      .then((res) => {
        setPreview(res.data[0].profile_img);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate(`/login`, { replace: true });
        }
      });
  }, []);

  const handleFileChange = (event) => {
    event.preventDefault(); // Prevents the default form submission and page refresh

    const file = event.target.files[0];
    if (file) {
      setProfilePicture(file);

      // Read the file and convert it to a data URL
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadProfilePicture = async () => {
    if (profilePicture) {
      try {
        const formData = new FormData();
        formData.append('file', profilePicture);

        const response = await axios.post(`${baseUrl}api/profileimgUpdate`, formData, { withCredentials: true });
        // Handle the response if needed
        console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          navigate('/login', { replace: true }); // Redirects to login page if not authorized
        }
      }
    }
  };

  const handleUpload = () => {
    setPreview(null);
  };
  useEffect(() => {
    uploadProfilePicture();
  }, [profilePicture]);

  return (
    <Box>
      <Box display="flex" justifyContent="center" maxWidth={550} height={300}>
        <Box width={250}>
          {preview ? (
            <img src={preview} width="250" height="100%" alt="#" />
          ) : (
            <Avatar src={imageUrl || '/broken-image.jpg'} sx={{ width: '100%', height: '100%' }} />
          )}

          <label htmlFor="upload-photo">
            <input
              style={{ display: 'none' }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />

            {logType !== 'USER' ? (
              <Button color="secondary" variant="contained" component="span" onClick={handleUpload}>
                Upload button
              </Button>
            ) : null}
          </label>
        </Box>
      </Box>
    </Box>
  );
}

export default UserSection;

import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

function ReportPrint() {
  const location = useLocation();
  // const { column } = location.state || {}; // Use the key 'column' to match the data passed from the Link
  console.log(location.state);
  const navigate = useNavigate();
  const printAreaRef = useRef(null);
  useEffect(() => {
    handlePrint();
  }, []);

  const handlePrint = () => {
    const printArea = printAreaRef.current;
    if (!printArea) {
      console.error("Element with ID 'print-area' not found.");
      return;
    }

    const printContents = printArea.innerHTML;
    const printWindow = window.open('', '_blank');

    if (!printWindow) {
      console.error("Failed to open the print window. Please check your browser's popup settings.");
      return;
    }
    // <p style='margin: 0;'>${dayjs(location.state.sDate).format('DD/MM/YYYY')} to ${dayjs(
    //     location.state.eDate
    //   ).format('DD/MM/YYYY')} </p>
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
        </head>
        <body>
          <div style='text-align: center;' className="vt_print_ther_head">
          <h2 style='margin: 2px;'>${location.state.class} ${location.state.division}</h2>
          </div>
          ${printContents}
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();

    setTimeout(() => {
      printWindow.close();
      navigate(-1);
    }, 50); // Adjust the timeout duration as needed
  };
  console.log(dayjs(location.state.sDate).format('DD/MM/YYYY'));
  console.log(dayjs(location.state.eDate).format('DD/MM/YYYY'));
  console.log(location.state.class);
  return (
    <>
      {/* <button onClick={handlePrint}>Print</button> */}
      <div>
        {/* Header section */}
        <div className="vt_print_ther_head" ref={printAreaRef} id="print-area">
          {/* <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'center' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px', width: 90 }}>Si.NO</th>
                <th style={{ border: '1px solid black', padding: '8px', width: 90 }}>Admission No</th>
                <th style={{ border: '1px solid black', padding: '8px', width: 100 }}>Date</th>
                <th style={{ border: '1px solid black', padding: '8px', width: 150 }}>Student Name</th>
                <th style={{ border: '1px solid black', padding: '8px', width: 110 }}>Module</th>
                <th style={{ border: '1px solid black', padding: '8px', width: 90 }}>Attendance</th>
              </tr>
            </thead>
            <tbody>
              {location.state.row.map((item, index) => (
                <tr key={item.AdmNo}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.AdmNo}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>
                    {dayjs(item.Date1).format('DD/MM/YYYY')}
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.Name}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.subject}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>
               
                    <div
                      style={{
                        background: item.stat === 0 ? 'lightRed' : item.Descr !== null ? 'skyBlue' : 'lightGreen',
                        borderRadius: 4,
                      }}
                    >
                      {item.stat === 0 ? 'Absent' : item.Descr !== null ? 'Authorized Absent' : 'Present'}{' '}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}
          <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'center' }}>
            <thead>
              <tr>
                {location.state.column.map((column, index) => (
                  <th
                    key={index}
                    style={{ border: '1px solid black', padding: '8px', width: column.field === '#' ? 60 : 150 }}
                  >
                    {column.field === 'Attendance'
                      ? location.state.stat === 0
                        ? 'Absent'
                        : location.state.Descr !== null
                        ? 'Authorized Absent'
                        : 'Present'
                      : column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {location.state.row.map((item, rowIndex) => (
                <tr key={rowIndex}>
                  {location.state.column.map((column, colIndex) => (
                    <td key={colIndex} style={{ border: '1px solid black', padding: '8px' }}>
                      {column.field === '#' ? (
                        rowIndex + 1
                      ) : column.field === 'Date1' ? (
                        dayjs(item.Date1).format('DD/MM/YYYY') // Format Date1 using dayjs
                      ) : column.field === 'time' ? (
                        dayjs(item.Date1, 'HH:mm:A').format('h:mm A') // Format the time field using dayjs
                      ) : column.field === 'Stat' ? (
                        <div
                          style={{
                            background: item.Stat === 0 ? '#ff7a7a' : item.Descr !== null ? '#94e0ff' : '#7fff7f',
                            borderRadius: 4,
                          }}
                        >
                          {item.Stat === 0 ? 'Absent' : item.Descr !== null ? 'Authorized Absent' : 'Present'}
                        </div>
                      ) : (
                        item[column.field]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ReportPrint;

import { Navigate, useRoutes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';

// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//

import Login from './Login';
import NotFound from './Page404';
import Register from './Register';
import ForgottPassword from './sections/auth/ForgottPassword';
import Colleagues from './common/Colleagues';
import ReportPrint from './common/ReportPrint';
import EachStudentPrint from './common/EachStudentPrint';

// --------- Admin Route---------//

const StaffMaster = lazy(() => import('./pages admin/master/staff master/StaffMaster'));
const DashboardApp = lazy(() => import('./pages admin/DashboardApp'));
const Master = lazy(() => import('./pages admin/master/Master'));
const Reports = lazy(() => import('./pages admin/report/Reports'));
const PasswordManager = lazy(() => import('./pages admin/PasswordManager'));
const Calender = lazy(() => import('./pages admin/Calender'));
const TypesOfModes = lazy(() => import('./pages admin/settings/TypesOfModes'));

// --------- User Route---------//

const DashboardAppStudent = lazy(() => import('./pages student/DashboardApp'));
const MarkAttendance = lazy(() => import('./pages student/MarkAttendance'));
const TakeBreak = lazy(() => import('./pages student/components/TakeBreak'));
const ReportForStudent = lazy(() => import('./pages student/AttendanceReport/ReportForStudent'));
const LeaveNote = lazy(() => import('./pages student/components/LeaveNote'));

// --------- Staff Route---------//

const DashboardAppTeacher = lazy(() => import('./pages teacher/DashboardApp'));
const Attendence = lazy(() => import('./pages teacher/attendance/attendanceRequest/Attendence'));
const GetExitNotification = lazy(() => import('./pages teacher/exitNotification/GetExitNotification'));
const Report = lazy(() => import('./pages teacher/reports/Report'));
const Profile = lazy(() => import('./Profile'));
const EditAttendance = lazy(() => import('./common/EditAttendance'));
const AbsentRequest = lazy(() => import('./pages teacher/absentRequests/AbsentRequest'));

// ----------------------------------------------------------------------
const LoadingFallback = () => <LinearProgress />;
export default function Router() {
  const loginRoute = useSelector((state) => state.loginRoute);
  return (
    <Suspense fallback={<LoadingFallback />}>
      {useRoutes([
        // --------- Admin Route---------//
        {
          path: '/dashboard/admin',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardApp /> },
            { path: 'master', element: <Master /> },
            { path: 'staffmaster', element: <StaffMaster /> },
            { path: 'att-report', element: <Reports /> },
            { path: 'editAtt', element: <EditAttendance /> },
            { path: 'passwords', element: <PasswordManager /> },
            { path: 'calender', element: <Calender /> },
            { path: 'modes', element: <TypesOfModes /> },
            // TypesOfModes
          ],
        },
        // --------- User Route---------//
        {
          path: '/dashboard/student',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardAppStudent /> },
            { path: 'mark-attendance', element: <MarkAttendance /> },
            { path: 'take.break', element: <TakeBreak /> },
            { path: 'subject_report', element: <ReportForStudent /> },
            { path: 'take_leave', element: <LeaveNote /> },
            { path: 'calender', element: <Calender /> },
          ],
        },
        // --------- Staff Route---------//
        {
          path: '/dashboard/teacher',
          element: <DashboardLayout />,
          children: [
            { path: 'app', element: <DashboardAppTeacher /> },
            { path: 'attendence', element: <Attendence /> },
            { path: 'attedanceReport', element: <Report /> },
            { path: 'editAtt', element: <EditAttendance /> },
            { path: 'exitNotification', element: <GetExitNotification /> },
            { path: 'absentReq', element: <AbsentRequest /> },
            { path: 'colleagues', element: <Colleagues /> },
          ],
        },
        {
          path: 'print',
          element: <ReportPrint />,
        },
        {
          path: 'schedule',
          element: <ReportPrint />,
        },
        {
          path: 'stdentPrint',
          element: <EachStudentPrint />,
        },
        {
          path: 'account',
          element: <Profile />,
        },
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'register',
          element: <Register />,
        },
        {
          path: 'login_tr',
          element: <ForgottPassword />,
        },
        {
          path: '/',
          element: <LogoOnlyLayout />,
          children: [
            { path: '/', element: <Navigate to={loginRoute} /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ])}
    </Suspense>
  );
}
